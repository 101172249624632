<template>
  <div id="visualization">
    <mainMenu></mainMenu>
    <div class="headerPreview">
      <div class="wrapper">
        <div class="sectionInfo">
          <h1 class="sectionTitle">3D визуализации</h1>
          <button type="button" class="btnAbout" v-on:click="showAbout = !showAbout">
            <span v-if="!showAbout">Читать о 3D моделях</span>
            <span v-else><icon name="angle-double-left" scale="0.8"></icon> Вернуться</span>
          </button>
          <h2 class="sectionDescription">
            Самый высокотехнологичный и информативный метод демонстрации и изучения
            <br />
            видов стоматологических услуг, анатомии и паталогических аномалий
          </h2>
        </div>
      </div>
    </div>
    <div class="visualizationView">
      <div class="wrapper">
        <router-view v-if="!showAbout"></router-view>
        <div v-if="showAbout" class="aboutInfo">
          <div class="aboutInfoTopBlock">
            <p>
              3D визуализации позволяют наглядно демонстрировать разного рода стоматологические объекты, процедуры,
              аномалии, результаты лечения, а так же виды протезирования и реставраций. Данный функционал незаменим для
              работы с пациентами. Визуализации с высокой реалистичностью и детализацией демонстрирует сложные
              Узкоспециализированные объекты.
            </p>
            <p class="text-center">Визуализации представлены двух типов: 3D и VR</p>
            <div class="imgBlock">
              <div class="imgBlockItem">
                <img src="./../../assets/images/demoModels/3d_icon.png" alt="" class="imgBlockItemImg" />
              </div>
              <div class="imgBlockItem">
                <img src="./../../assets/images/demoModels/vr_icon.png" alt="" class="imgBlockItemImg" />
              </div>
            </div>
          </div>
          <div class="aboutInfo3dBlock">
            <h3 class="text-center">3D визуализации</h3>
            <p>
              Данный тип визуализаций состоит из группы 3D моделей, определяющих единый объект. Каждой модели присвоены
              свойства, цвет и степень прозрачности, улучшающие демонстрационные свойства. Функционал позволяет в онлайн
              режиме менять прозрачность каждого элемента и демонстрировать скрытые элементы используя zoom.
            </p>
            <div class="imgBlock">
              <div class="imgBlockItem">
                <img src="./../../assets/images/demoModels/demo_preview_1.png" alt="" class="imgBlockItemImg" />
              </div>
              <div class="imgBlockItem">
                <img src="./../../assets/images/demoModels/demo_preview_2.png" alt="" class="imgBlockItemImg" />
              </div>
              <div class="imgBlockItem">
                <img src="./../../assets/images/demoModels/demo_preview_3.png" alt="" class="imgBlockItemImg" />
              </div>
            </div>
          </div>
          <div class="aboutInfoControls">
            <h3 class="text-center">Схема управления мышью</h3>
            <p>
              Данный тип визуализаций состоит из группы 3D моделей, определяющих единый объект. Каждой модели присвоены
              свойства, цвет и степень прозрачности, улучшающие демонстрационные свойства. Функционал позволяет в онлайн
              режиме менять прозрачность каждого элемента и демонстрировать скрытые элементы используя zoom.
            </p>
            <div class="imgBlock">
              <div class="imgBlockItem">
                <img src="./../../assets/images/demoModels/control_preview.png" alt="" class="imgBlockItemImg" />
              </div>
            </div>
          </div>
          <div class="aboutInfoVRControls">
            <h3 class="text-center">VR визуализации</h3>
            <p>
              Данные визуализации позволяют в режиме виртуальной реальности с высокой фотореалистичностью
              демонстрировать объекты с радиусом обзора 360 градусов по горизонтали и 90 по вертикали.
            </p>
            <div class="imgBlock">
              <div class="imgBlockItem">
                <img src="./../../assets/images/demoModels/vr_control_preview.png" alt="" class="imgBlockItemImg" />
              </div>
            </div>
            <p class="text-center zoomInfo">
              Так же в данной визуализации можно использовать увеличение объекта и передвижение по экрану.
            </p>
            <div class="imgBlock">
              <div class="imgBlockItem">
                <img src="./../../assets/images/demoModels/vr_zoom_preview.png" alt="" class="imgBlockItemImg" />
              </div>
            </div>
          </div>
          <div class="visualization_video">
            <div class="controls_video">
              <iframe src="https://www.youtube.com/embed/GsY34AHBWV4" frameborder="0" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mainFooter></mainFooter>
  </div>
</template>

<script>
import mainMenu from "./../mainMenu/mainMenu.vue";
import mainFooter from "./../mainFooter/mainFooter.vue";

export default {
  name: "visualization",
  components: { mainMenu, mainFooter },
  data() {
    return {
      showAbout: false,
    };
  },
};
</script>

<style lang="sass">
#visualization
	display: flex
	flex-direction: column
	min-height: calc(100vh - 155px)
	& .headerPreview
		display: flex
		min-height: 270px
		align-items: center
		padding: 0 2rem
		background: url('./../../assets/images/visualizationBackground.png')
		background-size: cover
		background-position: center center
		& .sectionInfo
			width: fit-content
			@media screen and (max-width: 426px)
				display: flex
				flex-direction: column
				align-items: center
				width: 100%
				text-align: center
			& .sectionTitle
				color: #3e3e3e
				padding-bottom: 1.5rem
				margin: 0 0 1.5rem
				border-bottom: 1px solid #f7941d
			& .btnAbout
				background: #f7941d
				color: #fff
				display: block
				padding: 0.5rem 2rem
				border: 1px solid transparent
				cursor: pointer
			& .sectionDescription
				font-family: 'Ropa Sans Pro SC Regular'
				font-weight: 400
				font-size: 1.3rem
				color: #3e3e3e
				margin-top: 1.5rem
	.visualizationView
		min-height: 50vh
		padding: 0 1rem
		& .aboutInfo
			padding: 2rem 0
			& .imgBlock
				display: flex
				justify-content: center
				@media screen and (max-width: 768px)
					flex-wrap: wrap
				& .imgBlockItem
					& .imgBlockItemImg
						display: block
						width: 100%
						height: auto
			& .aboutInfoTopBlock
				margin-bottom: 2rem
				@media screen and (max-width: 768px)
					.imgBlockItem
						width: 25%
				@media screen and (max-width: 425px)
					.imgBlockItem
						width: 50%
				& p
					margin-bottom: 0.5rem
			& .aboutInfo3dBlock
				margin-bottom: 2rem
				@media screen and (max-width: 768px)
					.imgBlockItem
						width: 25%
				@media screen and (max-width: 425px)
					.imgBlockItem
						width: 50%
				& H3
					margin-bottom: 0.75rem
				& p
					margin-bottom: 0.5rem
			& .aboutInfoControls
				margin-bottom: 2rem
				& H3
					margin-bottom: 0.75rem
				& p
					margin-bottom: 0.5rem
			& .aboutInfoVRControls
				margin-bottom: 2rem
				& H3
					margin-bottom: 0.75rem
				& p
					margin-bottom: 0.5rem
				& p.zoomInfo
					margin: 1rem 0
			& .visualization_video
				margin-bottom: 2rem
				& .controls_video
					position: relative
					padding-bottom: 65.25%
					padding-top: 30px
					height: 0
					overflow: auto
					-webkit-overflow-scrolling: touch
					border: solid black transparent
					& iframe
						position: absolute
						top: 0
						left: 0
						width: 100%
						height: 100%
	.visualizationCategories
		padding: 1rem 0rem
		column-count: 4
		column-gap: 2rem
		@media screen and (max-width: 769px)
			column-count: 2
			column-gap: 1rem
		@media screen and (max-width: 425px)
			column-count: 1
		& .visualizationItem
			display: flex
			flex-direction: column
			cursor: pointer
			@media screen and (max-width: 769px)
				margin-bottom: 1rem
			& .visualizationItemLogo
				display: block
				width: 100%
				height: auto
			& .visualizationItemContent
				font-weight: bold
				color: #fff
				min-height: 180px
				padding: 1rem 0 0 1.5rem
				font-size: 1rem
				@media screen and (max-width: 769px)
					min-height: 90px
	.visualizationItems
		padding: 0.5rem 0rem
		& .visualizationItems-goBack
			margin-bottom: 0.5rem
			& a
				display: inline-flex
				align-items: center
				padding: 0.5rem 0
				font-size: 18px
				color: #333
				transition: all 0.5s
				&:hover
					color: #5f5f5f
		& .visualizationItemBlock
			display: flex
			justify-content: space-between
			align-items: center
			margin-bottom: 2rem
			@media screen and (max-width: 700px)
				flex-direction: column
			& img
				height: 120px
			& .visualizationItemContent
				display: flex
				align-items: center
				justify-content: space-between
				width: 100%
				color: #fff
				font-size: 1.2rem
				font-weight: bold
				@media screen and (max-width: 870px)
					flex-direction: column
				& .visualizationItemSearchBar
					display: flex
					flex-direction: column
					min-width: 320px
					padding: 1rem 1rem
					@media screen and (max-width: 426px)
						min-width: 280px
					& .visualizationItemSearchTitle
						display: flex
						font-weight: 400
						padding-bottom: 1rem
						@media screen and (max-width: 870px)
							align-items: center
							justify-content: center
							width: 100%
							align-items: center
							font-size: 1rem
					& .visualizationItemInput
						display: block
						position: relative
						width: 100%
						& .searchBar
							display: block
							width: 100%
							padding: 3px 5px
							border: 1px solid transparent
						& .iconSearch
							display: block
							color: #000
							position: absolute
							right: 5px
							top: 50%
							transform: translate(0, -50%)
					& .visualizationItemInput-info
						display: block
						font-size: 14px
						padding-top: 0.25rem
				& .visualizationItemContentOtherCategories
					display: flex
					align-items: center
					justify-content: space-evenly
					@media screen and (max-width: 380px)
						flex-direction: column
					& a
						display: block
						position: relative
						margin-right: 1rem
						@media screen and (max-width: 374px)
							margin-right: 0
						& img
							height: calc(120px - 2rem)
							cursor: pointer
							@media screen and (max-width: 426px)
								height: auto
								width: calc(270px / 3)
							@media screen and (max-width: 374px)
								width: 150px
						& .visualizationItemContentOtherCategoriesTitle
							display: block
							position: absolute
							bottom: 0
							left: 0
							right: 0
							font-size: 14px
							padding: 0.5rem 0
							color: #fff
							text-align: center
							text-shadow: 0px 0px 1px #000
		& .visualizationItemList
			display: grid
			grid-template-columns: 1fr 1fr 1fr 1fr
			grid-gap: 1rem
			@media screen and (max-width: 870px)
				grid-template-columns: 1fr 1fr 1fr
			@media screen and (max-width: 700px)
				grid-template-columns: 1fr 1fr 1fr
			@media screen and (max-width: 600px)
				grid-template-columns: 1fr 1fr
			@media screen and (max-width: 400px)
				grid-template-columns: 1fr
	.surgeryBg
		background: #f5989d
		transition: all 0.5s
		&:hover
			background: #f77a81
	.orthodonticsBg
		background: #3cb878
		transition: all 0.5s
		&:hover
			background: #07b75b
	.orthopedicsBg
		background: #bd8cbf
		transition: all 0.5s
		&:hover
			background: #ba66bd
	.terapyBg
		background: #448ccb
		transition: all 0.5s
		&:hover
			background: #117ede
</style>
